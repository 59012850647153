import type { ParentComponent } from "solid-js";
import type { MediaOverlayProps } from "~/components/shared/MediaOverlay";

import { createContext, useContext } from "solid-js";
import { createStore, unwrap } from "solid-js/store";
import { isProd } from "~/lib/env";

export type MediaOverlayContext = [
  {
    type: MediaOverlayProps["type"] | undefined;
    content: MediaOverlayProps["content"] | undefined;
  },
  {
    setMediaOverlay: (
      overlay_type: MediaOverlayContext[0]["type"],
      overlay_values: MediaOverlayContext[0]["content"],
    ) => void;
    unsetMediaOverlay: () => void;
  },
];

const MediaOverlayContext = createContext<MediaOverlayContext>([
  { type: undefined, content: undefined },
  {
    setMediaOverlay: () => undefined,
    unsetMediaOverlay: () => undefined,
  },
]);

export const MediaOverlayProvider: ParentComponent<{
  type?: MediaOverlayContext[0]["type"];
  content?: MediaOverlayContext[0]["content"];
}> = (props) => {
  const [state, setState] = createStore({
      // eslint-disable-next-line solid/reactivity
      type: props.type || undefined,
      // eslint-disable-next-line solid/reactivity
      content: props.content || undefined,
    }),
    store: MediaOverlayContext = [
      state,
      {
        setMediaOverlay(
          overlay_type: MediaOverlayContext[0]["type"],
          overly_content: MediaOverlayContext[0]["content"],
        ) {
          setState("type", overlay_type);
          setState("content", overly_content);
          if (!isProd()) {
            console.groupCollapsed(`🎬 MediaOverlay opening: ${state.type}`);
            console.log("Values:", unwrap(state.content));
            console.groupEnd();
          }
        },
        unsetMediaOverlay() {
          !isProd() && console.log("🎬 MediaOverlay closing");
          setState("type", undefined);
          setState("content", undefined);
        },
      },
    ];

  return (
    <MediaOverlayContext.Provider value={store}>
      {props.children}
    </MediaOverlayContext.Provider>
  );
};

export function useMediaOverlayContext() {
  return useContext(MediaOverlayContext);
}
